.caractersTableSize {
  width: 5ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn-material:focus {
  background-color: #f7fafc;
  color: #001;
}
.btn-filter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 0.5em;
  margin-left: 3em;
}
@media screen and (max-width: 1640px) {
  .btn-filter {
    margin-left: 1em;
  }
}
@media screen and (max-width: 1640px) {
  .btn-filter {
    margin-left: 0em;
  }
}
