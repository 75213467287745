.btn-filter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 5%;
  margin-bottom: 0.5em;
  margin-left: 3em;
}
@media screen and (max-width: 1770px) {
  .btn-filter {
    margin-left: 1em;
  }
}
@media screen and (max-width: 1640px) {
  .btn-filter {
    margin-left: 0em;
  }
}

.iconFilter {
  color: #1193EF !important;
    margin-right: 0.2em !important;
    width: 19px !important;
}

@media screen and (max-width: 1000px) {
  .iconFilter{
    display: none !important;
  }
}