.descricaAlta {
  width: 28ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.descricaoBaixa {
  width: 27ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.denominacaoAlta {
  width: 23ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.denominacaoBaixa {
  width: 23ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* .container-performer{
} */
/* @media screen and (max-width: 1236px) {
    .container-performer{
        columns: 1; 
    }
} */
@media screen and (max-width: 480px) {
  .descricaAlta {
    width: 20ch;
  }
  .programadoAlta {
    width: 20ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .programadoMedia {
    width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .programadoNenhuma {
    width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .denominacaoAlta {
    width: 12ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .denominacaoMedia {
    width: 12ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .denominacaoNenhuma {
    width: 12ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .cardContainerMedia {
    margin-left: -15px;
    margin-right: -35px;
  }
  .descricaoBaixa {
    width: 17ch;
  }
  .programacaoBaixa {
    width: 18ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .denominacaoBaixa {
    width: 12ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .cardContainerAlta {
    margin-left: -16px;
    margin-right: -35px;
  }
  .cardContainer {
    margin-left: -16px;
    margin-right: -18px;
  }
}
.badgeCustomization {
  background: #fdfd03;
  border-radius: 4px;
}
.txtBadge {
  font-size: 0.8em;
}
