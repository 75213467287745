@import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
.custom-field-row {
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
  font-size: 13px;
  opacity: .8;  
}

.e-resource-column{
  /* padding: 10px; */
  /* border: 2px solid red; */
  width: 20% !important;
}
.e-parent-node{
  background-color: #eeee !important;
}

.e-dialog {
  min-width: 50% !important;
  min-height: 50% !important;
}