.caractersTableSize {
  width: 5ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-material {
  /* display: flex; */
  /* justify-content: center; */
  /* align */
  max-width: 200px;
  max-height: 25px;
}
.btn-material:focus {
  background-color: #f7fafc;
  color: #001;
}
.btn-filter {
  /* display: flex !important; */
  /* align-items: center; */
  /* justify-content: center;  */
  margin-bottom: 0.5em;
  margin-left: 3em;
}

@media screen and (max-width: 1640px) {
  .btn-filter {
    margin-left: 1em;
  }
}
@media screen and (max-width: 1640px) {
  .btn-filter {
    margin-left: 0em;
  }
}

.resizer {
  display: inline-block;
  /* background: #11BEEE; */
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
}
.resizer.isResizing {
  background: red;
}
.tableResize {
  display: inline-block;
  border-spacing: 0;
  /* border: 1px solid black; */
}
