.tab {
  /* text-transform: none; */
  /* border: none; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.tab:hover {
  /* background-color: #e8e8e8; */
}
