.caractersTableSize {
  width: 5ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.centerContent {
  text-align: center;
}
.btn-filter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 0.5em;
  margin-left: 4em;
}
@media screen and (max-width: 1640px) {
  .btn-filter {
    margin-left: 1em;
  }
}
@media screen and (max-width: 1640px) {
  .btn-filter {
    margin-left: 0em;
  }
}

.caractersTableSize1 {
  /* COLOR:#e3e4e6 */
  /* width: 5ch;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap; */
}
/* .teste{
    border: 2px solid red;

    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    color: rgb(189, 189, 189)
} */
