.btn-stepper {
  color: #9e9e9e;
  padding: 1.2em;
  background-color: transparent;
  border: none;
  border-radius: 5px;
}
.styleBadgeId {
  background: #dddada;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
}
.caractersTableSize {
  width: 5ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.btn-stepper:focus {
  color: #3f51b5;
}
.custom-timeline {
  margin-left: 20px;
}

.custom-timeline .rs-timeline-item-custom-dot .rs-icon {
  background: #fff;
  top: 0;
  left: -2px;
  border: 2px solid #ddd;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 18px;
  padding-top: 9px;
  color: #999;
  margin-left: -13px;
}

.custom-timeline .rs-timeline-item-content {
  margin-left: 24px;
  /* color: #dddada */
}
.btn-filter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 0.5em;
  margin-left: 3em;
}
@media screen and (max-width: 1770px) {
  .btn-filter {
    margin-left: 1em;
  }
}
@media screen and (max-width: 1640px) {
  .btn-filter {
    margin-left: 0em;
  }
}
