.datetimePicker {
  padding: 0.4em 0.5em;
  border-radius: 5px;
  border: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

.datetimePicker:focus {
  border-bottom: 2px solid #2b70d3;
}

.datetimePicker::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.datetimePicker::content {
  color: transparent;
}
